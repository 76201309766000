<template>
  <div class="drawer">
    <a-drawer
        :placement="placement"
        :closable="true"
        :visible="visible"
        @close="onClose"
    >
      <div
          v-for="(item,index) in menus"
          :key="index"
          @click.stop="handleClickMenu(item)"
      >
        <div class="menu-item">
          <span>{{ item.name }}</span>
          <img src="../assets/imgs/index/right@2x.png" class="right-icon">
        </div>
        <div class="second-menu" v-if="showSecondMenu&&item.key==='product'">
          <p @click="downLoad(1)">广烁电子印章客户端</p>
          <p @click="downLoad(4)">广烁电子印章签章客户端V2.0（使用说明书）</p>
          <p @click="downLoad(2)">广烁电子印章中间件</p>
<!--          <p @click="downLoad(3)">广烁印章排版系统</p>-->
          <p class="cailiao" @click.stop="toPage('/Product/SealMaterial')">印章材料</p>
          <p @click.stop="toPage('/Product/SealingEquipment')">印章设备</p>
        </div>
      </div>
    </a-drawer>
  </div>
</template>

<script>
export default {
  name: 'Menu',
  data() {
    return {
      visible: false,
      placement: 'right',
      showSecondMenu: false,
      menus: [
        {
          name: '首页',
          path: '/',
        },
        {
          name: '国产化方案',
          path: '/LocalizationPlan',
        },
        {
          name: '电子印章',
          path: '/eseal',
        },
        {
          name: '刻企服务',
          path: 'https://kq.lngsyz.com/login',
          isExternal: true,
        },
        // {
        //   name: '辽宁政采',
        //   path: '/lnzc',
        // },
        {
          name: '产品中心',
          key: 'product',
        },
        // {
        //   name: '申请有礼',
        //   path: 'https://kq.lngsyz.com/collect/sealInfo/toMakeSeal',
        //   isExternal: true,
        // },
        {
          name: '资源下载',
          path: '/ResourcesDownload',
        },
        {
          name: '关于广烁',
          path: '/About',
        },
        {
          name: '联系我们',
          path: '/ContactUs',
        },
      ],
      links: [
        '/app/广烁电子签章系统(客户端)V2.0.1.19_政企通用互联网版(20240529).msi',
        '/app/广烁电子签章系统(中间件)V2.0.1.14_印章制作企业专用(20231218)_正式版.msi',
        '/app/广烁印章排版系统V1.5.msi',
        '/app/sealclienthelp_v2.0.pdf',
      ],
    };
  },
  methods: {
    onClose() {
      this.visible = false;
    },
    onOpen() {
      this.visible = true;
    },
    handleClickMenu(item) {
      if (item.key === 'product') {
        this.showSecondMenu = !this.showSecondMenu;
      }
      if (item.isExternal) {
        window.open(item.path);
      } else if (item.path) {
        if (item.path !== this.$route.path) {
          this.$router.push(item.path);
        }
        this.onClose();
      }
    },
    toPage(path) {
      this.showSecondMenu = false;
      if (path !== this.$route.path) {
        this.$router.push(path);
      }
    },
    downLoad(index) {
      window.open(this.links[index - 1]);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .ant-drawer-content-wrapper {
  width: calc(~'100% - 16px') !important;
}

.menu-item {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  display: flex;
  justify-content: space-between;
  padding: 14px 24px 14px 20px;
  margin: 0;
}

.second-menu {
  padding-left: 40px;
  color: #333333;

  p {
    padding: 14px 0;
    margin: 0;
  }
}

.right-icon {
  width: 9px;
  height: 16px;
}

/deep/ .ant-drawer-body {
  padding: 0;
}

/deep/ .ant-drawer-header-no-title {
  height: 48px;
}
</style>
