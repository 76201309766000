<template>
  <div :class="['content', 'index-container',
  {'mobile-download-content':clientWidth<=mobileWidth}]">
    <common-header
        :is-show-menu="true"
        :client-width="clientWidth"
        current-menu="about"
        @openMobileMenu="openMobileMenu"/>
    <div class="wapper">
      <p class="title">联系我们</p>
      <div class="contact-body">
        <ul :class="contactIconClazz">
          <li>
            <s class="split-line"></s>
            <div class="ic-img">
              <img src="../../assets/imgs/about/tel_gm.png">
              <p class="ic-text">电话</p>
            </div>
            <p>
              <a class="ic-cont">服务热线：0411-82951884</a>
            </p>
          </li>
          <li>
            <div class="ic-img">
              <img src="../../assets/imgs/about/mail.jpg">
              <p class="ic-text">邮箱</p>
            </div>
            <p>
              <a class="ic-cont" href="mailto:info@lngsyz.cn">info@lngsyz.cn</a>
            </p>
          </li>
        </ul>
        <p class="contact-addr">地址：辽宁省大连市中山区华乐街滨景园1号</p>
      </div>
    </div>
    <common-footer v-show="clientWidth>mobileWidth"/>
    <mobile-footer v-show="clientWidth<=mobileWidth"/>
    <slide-menu ref="slideMenu"/>
  </div>
</template>
<script>
import CommonHeader from '@components/CommonHeader.vue';
import CommonFooter from '@components/CommonFooter.vue';
import MobileFooter from '@components/MobileFooter.vue';
import SlideMenu from '../../components/Menu.vue';

export default {
  name: 'ResourcesDownload',
  components: {
    CommonHeader,
    CommonFooter,
    MobileFooter,
    SlideMenu,
  },
  data() {
    return {
      clientWidth: 1920,
    };
  },
  computed: {
    contactIconClazz() {
      return this.clientWidth <= this.mobileWidth ? 'mobile-contact-icon' : 'contact-icon';
    },
    mobileWidth() {
      return this.$store.state.common.mobileWidth;
    },
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.clientWidth = document.body.clientWidth;
    },
    openMobileMenu() {
      this.$refs.slideMenu.onOpen();
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../less/mobileIndex.less";

.content {
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 80px;

  .title {
    font-size: 36px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 36px;
    position: relative;
    text-align: center;
    margin: 95px 0;

    &::after {
      position: absolute;
      content: '';
      width: 120px;
      height: 8px;
      background: #F75959;
      border-radius: 4px;
      bottom: -24px;
      left: 50%;
      transform: translateX(-50%);
      animation: fadeIn 1s 1;
    }
  }

  .contact-body {
    padding-bottom: 90px;

    .contact-addr {
      font-size: 20px;
      text-align: center;
      color: #333;
    }

    .contact-icon {
      text-align: center;
      padding: 40px 0;

      > li {
        width: 25%;
        display: inline-block;
        position: relative;
      }

      .ic-img {
        height: 80px;
        text-align: center;

        img {
          display: block;
          margin: 0 auto;
        }
      }

      .split-line {
        height: 80px;
        width: 1px;
        background: #ccc;
        top: 50%;
        margin-top: -40px;
        display: block;
        right: 0;
        position: absolute;
      }

      .ic-text {
        font-size: 14px;
        color: #666;
        margin-top: 10px;
      }

      .ic-cont {
        font-size: 20px;
        white-space: nowrap;
        margin-top: 37px;
        display: block;
        color: #333;
      }

      .ic-cont:hover {
        color: #F75959;
        text-decoration: none;
      }
    }

    .mobile-contact-icon {
      padding: 30px 0 5px;
      width: 350px;
      margin: 0 auto;

      > li {
        display: flex;
        align-items: baseline;
      }

      .ic-img {
        img {
          display: block;
          margin: 0 auto;
          width: 30px;
        }
      }

      .ic-text {
        font-size: 14px;
        color: #666;
        margin-top: 10px;
        display: none;
      }

      .split-line {
        display: none;
      }

      .ic-cont {
        font-size: 16px;
        white-space: nowrap;
        color: #333;
        margin-left: 10px;
        position: relative;
        bottom: 3px;
      }

      .ic-cont:hover {
        color: #F75959;
        text-decoration: none;
      }
    }
  }
}

.mobile-download-content {
  .title {
    font-size: 18px;
    margin: 48px 0;

    &::after {
      width: 60px;
      height: 4px;
      bottom: -4px;
    }
  }

  .contact-body {
    .contact-addr {
      font-size: 16px;
      width: 350px;
      margin: 0 auto;
      text-align: left;
    }
  }
}
</style>
